import { computed, ssrRef, useContext } from '@nuxtjs/composition-api';
import xml2js from 'xml2js';
import { useUiNotification } from '~/composables/useUiNotification';

export const getAddressValidation = () => {
    const {app} = useContext();
    const result = ssrRef(null, `getAddressValidation-result`);
    const loading = ssrRef(false, `getAddressValidation-loading`);
    const error = ssrRef(null, `getAddressValidation-error`);
    const apiResponse = ssrRef(null);
    const { send: sendNotification } = useUiNotification();

    /*
     * Call tnt API to get address validation
     */
    const get = async (params) => {
        try {
            loading.value = true;

            const addressParams = '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>' +
                '<!DOCTYPE Document SYSTEM "ckaIN.dtd">' +
                '<Document>' +
                '    <Application>MYCKA</Application>' +
                '    <Version>1.0</Version>' +
                '    <AddressDetails>' +
                `        <CheckAddress>${params.street}</CheckAddress>` +
                `        <CheckPostcode>${params.postcode}</CheckPostcode>` +
                `        <CheckTown>${params.city}</CheckTown>` +
                `        <CheckProvince>${params.region}</CheckProvince>` +
                '    </AddressDetails>' +
                '</Document>';

            let response = await app.context.$vsf.$tntServices.api.getAddressValidation(addressParams);

            xml2js.parseString(response, (err, success) => {
                if (err) {
                    result.value = false;
                    console.debug(err);
                } else {
                    apiResponse.value = success;
                }
            });

            result.value = !(apiResponse?.value?.ResultSet && apiResponse?.value?.ResultSet.Complete === undefined);

            console.debug('getAddressValidation result', result.value);
            error.value = null;
        } catch (exception) {
            error.value = exception;
            console.debug(`getAddressValidation error`, exception);
        } finally {
            loading.value = false;
        }
    };

    return {
        get,
        result: computed(() => result.value),
        loading: computed(() => loading.value),
        error: computed(() => error.value)
    }
};
